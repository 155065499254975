import React, { useState, useEffect, useRef } from "react"
import { Col, Form, InputGroup } from "react-bootstrap"
import ReactDatePicker from "react-datepicker"
import moment from "moment"
import { Controller } from "react-hook-form"
import { IconExternalLink, IconEye, IconEyeOff } from "@tabler/icons-react"
import { Link } from "react-router-dom"
import SelectBox from "../SelectBox"
import "react-datepicker/dist/react-datepicker.css"
import { getYear, getMonth } from "date-fns"
import range from "lodash/range" // Optional, can also use native JS functions

export const FormField = ({
  label,
  name,
  type,
  register,
  error = "",
  placeholder = "",
  size = "6",
  hint = "",
  autoFocus,
  required,
  downloadLink = false,
  ...props
}) => (
  <Form.Group as={Col} md={size}>
    <Form.Label className={`${required ? "required" : ""}`}>
      {label}{" "}
      <Form.Text className="form-label-description" muted>
        {hint}
      </Form.Text>
    </Form.Label>
    <Form.Control
      type={type}
      isInvalid={error ? true : false}
      placeholder={placeholder}
      {...register(name)}
      autoFocus={autoFocus}
      {...props}
    />
    {downloadLink && (
      <Form.Text as="div" className="text-truncate text-break text-end">
        {downloadLink}
      </Form.Text>
    )}
    <Form.Control.Feedback type="invalid">{error.message}</Form.Control.Feedback>
  </Form.Group>
)
export const FormFieldPassword = ({
  label,
  name,
  type,
  register,
  error = "",
  placeholder = "",
  size = "6",
  hint = "",
  autoFocus,
  required,
  downloadLink = false,
  ...props
}) => {
  const [isShow, setIsShow] = useState(true)
  return (
    <Form.Group as={Col} md={size}>
      <Form.Label className={`${required ? "required" : ""}`}>
        {label}{" "}
        <Form.Text className="form-label-description" muted>
          {hint}
        </Form.Text>
      </Form.Label>
      <InputGroup className={`input-group-flat ${error ? "is-invalid" : ""}`}>
        <Form.Control
          type={`${isShow ? "password" : "text"}`}
          isInvalid={error ? true : false}
          placeholder={placeholder}
          {...register(name)}
          autoFocus={autoFocus}
          {...props}
        />
        <InputGroup.Text as="span" className={`${error && "border-danger"}`}>
          <Link
            to={""}
            tabIndex="-1"
            className={`${error && "text-danger"}`}
            onClick={() => setIsShow(!isShow)}
          >
            {isShow ? <IconEye stroke={1.5} size={20} /> : <IconEyeOff stroke={1.5} size={20} />}
          </Link>
        </InputGroup.Text>
      </InputGroup>
      {downloadLink && (
        <Form.Text as="div" className="text-truncate text-break text-end">
          {downloadLink}
        </Form.Text>
      )}
      <Form.Control.Feedback type="invalid">{error.message}</Form.Control.Feedback>
    </Form.Group>
  )
}
export const FormFieldCheck = ({
  size = "6",
  label,
  name,
  type = "radio",
  options,
  register,
  error = "",
  inline = true,
  required,
  className = ""
}) => {
  return (
    <Form.Group as={Col} md={size} className={className}>
      <Form.Label className={`${required && "required"}`}>{label}</Form.Label>
      {options.map((option) => (
        <Form.Check
          inline={inline}
          key={option.value || option.label}
          type={type}
          className={`mt-2 ${error ? "is-invalid" : ""}`}
        >
          <Form.Check.Input
            type={type}
            {...register(name)}
            value={option.value}
            isInvalid={error ? true : false}
            id={option.value}
          />
          <Form.Check.Label htmlFor={option.value}>
            {option.label}{" "}
            {option.document && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.REACT_APP_UPLOAD_BASE_URL}${option.document}`}
                className="ms-2 d-inline-block text-truncate text-break position-absolute"
                style={{ maxWidth: "calc(100% - 50%)" }}
              >
                <IconExternalLink className="icon me-1" />
                {option.document_name}
              </a>
            )}
          </Form.Check.Label>
        </Form.Check>
      ))}
      <Form.Control.Feedback type="invalid">{error.message}</Form.Control.Feedback>
    </Form.Group>
  )
}
export const FormFieldSelect = ({
  size = "6",
  label,
  name,
  placeholder = "Select",
  control,
  options = [],
  error = "",
  isClearable = false,
  isSearchable = false,
  required,
  ...props
}) => {
  return (
    <Form.Group as={Col} md={size}>
      <Form.Label className={`${required ? "required" : ""}`}>{label}</Form.Label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <SelectBox
            {...field}
            isClearable={isClearable}
            isSearchable={isSearchable}
            options={options}
            className={`form-control p-0 border-0 ${error ? "is-invalid" : ""}`}
            placeholder={placeholder}
            {...props}
          />
        )}
      />
      <Form.Control.Feedback type="invalid">{error.message}</Form.Control.Feedback>
    </Form.Group>
  )
}
export const FormFieldDatePicker = ({
  label,
  name,
  control,
  error = "",
  placeholder = "MM-DD-YYYY",
  size = "6",
  hint = "",
  autoFocus,
  required,
  showTimeSelect=false,
  dateFormat="MM/dd/yyyy",
  ...props
}) => {
  const [isYearOpen, setIsYearOpen] = useState(false)
  const yearRef = useRef(null)

  const currentYear = new Date().getFullYear()
  const years = range(1950, currentYear + 1)
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]

  const toggleYearDropdown = () => {
    setIsYearOpen(!isYearOpen)
  }

  const handleYearChange = (changeYear, year) => {
    changeYear(year)
    setIsYearOpen(false)
  }

  const commonStyles = {
    appearance: "none",
    border: "1px solid #aeaeae",
    borderRadius: "3px",
    padding: "5px 10px",
    marginRight: "5px",
    cursor: "pointer",
    fontSize: "14px"
  }

  const CustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  }) => (
    <div className="custom-date-picker-header">
      <div className="date-piker-header">
        <div
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          style={{ ...commonStyles }}
          className="date-navigation-btn date-navigation-previous"
        ></div>
        <h6>
          {months[getMonth(date)]} {getYear(date)}
        </h6>
        <div
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          style={{ ...commonStyles }}
          className="date-navigation-btn date-navigation-next"
        ></div>
      </div>
      <div className="month-year-grp">
        <div style={{ position: "relative", marginRight: "5px" }}>
          <span className="arrow-down"></span>
          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
            style={commonStyles}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div style={{ position: "relative" }}>
          <div
            className="year-dropdown-btn"
            onClick={toggleYearDropdown}
            ref={yearRef}
            style={commonStyles}
          >
            {getYear(date)}
          </div>
          {isYearOpen && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                left: "0",
                right: "0",
                backgroundColor: "white",
                border: "1px solid #ccc",
                zIndex: 1000,
                maxHeight: "200px",
                overflowY: "auto",
                scrollbarWidth: "thin"
              }}
              className="year-dropdown"
            >
              {years.map((year) => (
                <div
                  key={year}
                  onClick={() => handleYearChange(changeYear, year)}
                  style={{
                    padding: "5px 10px",
                    cursor: "pointer",
                    textAlign: "left",
                    color: year === getYear(date) ? "#fff" : "#000",
                    backgroundColor: year === getYear(date) ? "#006ce1" : "white"
                  }}
                >
                  {year}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )

  return (
    <Form.Group md={size}>
      <Form.Label>
        {label}{" "}
        {hint && (
          <Form.Text className="form-label-description" muted>
            {hint}
          </Form.Text>
        )}
      </Form.Label>
      <Controller
        control={control}
        name={name}
        defaultValue={null}
        render={({ field }) => (
          <ReactDatePicker
            renderCustomHeader={CustomHeader}
            selected={field.value}
            onChange={(date) => field.onChange(date)}
            dateFormat={dateFormat}
            wrapperClassName="w-100"
            className={`form-control ${error ? "is-invalid" : ""}`}
            placeholderText={placeholder}
            autoFocus={autoFocus}
            showTimeSelect={showTimeSelect}
            {...props}
          />
        )}
      />
      {error && <Form.Text className="text-danger">{error.message}</Form.Text>}
    </Form.Group>
  )
}
export const FormFieldDatePickers = ({
  label,
  name,
  control,
  error = "",
  placeholder = "",
  size = "6",
  hint = "",
  autoFocus,
  required,
  maxDate = false,
  ...props
}) => {
  const fieldName = name
  return (
    <Form.Group as={Col}>
      <Form.Label>
        {label}{" "}
        <Form.Text className="form-label-description" muted>
          {hint}
        </Form.Text>
      </Form.Label>
      <Controller
        control={control}
        name={fieldName}
        defaultValue={null}
        render={({ field }) => (
          <ReactDatePicker
            wrapperClassName="w-100"
            selected={field.value ? moment(field.value, "DD-MMM-YYYY").toDate() : null}
            onChange={(date) => {
              const formattedDate = moment(date).format("DD-MMM-YYYY")
              field.onChange(formattedDate)
            }}
            className={`${error ? "is-invalid" : ""}`}
            placeholderText={placeholder}
            autoFocus={autoFocus}
            dateFormat="dd-MMM-yyyy"
            fixedHeight
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={maxDate}
            {...props}
          />
        )}
      />
      {error && <Form.Text className="text-danger">{error.message}</Form.Text>}
    </Form.Group>
  )
}


export const TimePicker = ({
  label,
  name,
  control,
  error = "",
  placeholder = "",
  size = "6",
  hint = "",
  timeIntervals=15,
  autoFocus,
  required,
  ...props
}) => {
  const fieldName = name;
  return (
    <Form.Group as={Col}>
      <Form.Label>
        {label}{" "}
        <Form.Text className="form-label-description" muted>
          {hint}
        </Form.Text>
      </Form.Label>
      <Controller
        control={control}
        name={fieldName}
        defaultValue={null}
        render={({ field }) => (
          <ReactDatePicker
            wrapperClassName="w-100"
            selected={field.value ? moment(field.value, "h:mm A").toDate() : null}
            onChange={(time) => {
              const formattedTime = moment(time).format("h:mm A");
              field.onChange(formattedTime);
            }}
            className={`${error ? "is-invalid" : ""}`}
            placeholderText={placeholder}
            autoFocus={autoFocus}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={timeIntervals}
            timeCaption="Time"
            dateFormat="h:mm aa"
            {...props}
          />
        )}
      />
      {error && <Form.Text className="text-danger">{error.message}</Form.Text>}
    </Form.Group>
  );
};



// export const TimePicker = ({
//   label,
//   name,
//   control,
//   error = "",
//   placeholder = "",
//   size = "6",
//   hint = "",
//   autoFocus,
//   dateFormat="h:mm: aa",
//   defaultTime="04:30 PM",
//   // defaultTime,
//   required,
//   ...props
// }) => {
//   const defaultValue = defaultTime ? moment(defaultTime, "h:mm A").toDate() : "05:30 PM"; //
//   const fieldName = name;
//   console.log(defaultTime,'defaultTime');
//   return (
//     <Form.Group as={Col}>
//       <Form.Label>
//         {label}{" "}
//         <Form.Text className="form-label-description" muted>
//           {hint}
//         </Form.Text>
//       </Form.Label>
//       <Controller
//         control={control}
//         name={fieldName}
//         defaultValue={null}
//         render={({ field }) => (
//           <ReactDatePicker
//             selected={field.value || defaultValue}
//             onChange={(time) => {
//               console.log("🚀 ~ time:", time)
              
//               field.onChange(moment(time).format(dateFormat))
//             }}
//             showTimeSelect
//             showTimeSelectOnly
//             timeIntervals={15} // Customize intervals as needed
//             timeCaption="Time"
//             dateFormat={dateFormat}
//             className={`${error ? "is-invalid" : ""}`}
//             placeholderText={placeholder}
//             autoFocus={autoFocus}
//             {...props}
//           />
//         )}
//       />
//       {error && <Form.Text className="text-danger">{error.message}</Form.Text>}
//     </Form.Group>
//   );
// };
