import React, { useEffect, useRef, useState } from "react"
import moment from "moment"
import html2pdf from "html2pdf.js"
import { useForm } from "react-hook-form"
import { Form, Button } from "react-bootstrap"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import Loader from "../Loader"
import * as SurveyAPI from "../../api/SurveyAPI"
import CommonTextInput, { CommonTextInputExport } from "../Input/CommonTextInput"
import CheckBox from "../Input/CheckBox"
import { FormFieldDatePickers } from "../Input/FormField"
import { DRAGGABLE_FORM_CONSTANTS, isPermission } from "../../common/constants"
import Status from "../Status"
import { showUpdatedToasterMessage } from "../../store/slices/toaster/toasterslice"
import ConfirmModal from "./ConfirmModal"
import { Denied_Msg } from "../../assets/data/menu"
import { checkPermission } from "../../api/AuthApi"
const REJECT_SURVEY = {
  show: false,
  isLoading: false,
  title: "Not approve",
  description: "Do you really want to not approve this survey?",
  action: ""
}
const PreviewSurvey = ({ isActive, permission }) => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id, status } = useParams()
  const headerRef = useRef(false)
  let c = 0
  const [modal, setModal] = useState(REJECT_SURVEY)
  const [loading, setLoading] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  const [progress, setProgress] = useState(0)
  const [surveyDetail, setSurveyDetail] = useState({
    client_name: "",
    project_name: "",
    round_name: "",
    site_name: "",
    site_location: "",
    fieldrep_name: "",
    schedule_date: "",
    status: "",
    service_code: "",
    template_column: ""
  })

  const [surveyData, setSurveyData] = useState([])
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: {}
  })

  const markAsApprove = async () => {
    try {
      const response = await SurveyAPI.approved(id)
      if (response.status == true) {
        dispatch(
          showUpdatedToasterMessage({
            message: "Survey approved successfully",
            type: "success"
          })
        )
        setSurveyDetail({ ...surveyDetail, status: response.data.status })
        navigate("/surveys")
      }
    } catch (e) {
    } finally {
    }
  }
  const markAsNotApprove = async () => {
    try {
      const response = await SurveyAPI.notApproved(id)
      if (response.status == true) {
        dispatch(
          showUpdatedToasterMessage({
            message: "Survey not approved",
            type: "success"
          })
        )
        setSurveyDetail({ ...surveyDetail, status: response.data.status })
        navigate("/surveys")
      }
    } catch (e) {
    } finally {
    }
  }

  const fetchPreviewData = async () => {
    setLoading(true)
    try {
      const response = await SurveyAPI.previewExport(id, status)
      if (response.status == true) {
        setSurveyData(response.data)
      }
      setSurveyDetail(response.data[response.data.length - 1])
      const defaultValues = {}

      response.data.forEach((item, index) => {
        const uniqueKey = item.ques_no != "name" ? item.ques_no : ""
        defaultValues[uniqueKey] = item.ans == "null" || null ? null : item.ans
      })
      Object.keys(defaultValues).forEach((key) => {
        setValue(key, defaultValues[key])
      })
    } catch (error) {
      dispatch(
        showUpdatedToasterMessage({
          message: error.message,
          type: "error"
        })
      )
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchPreviewData()
  }, [])

  const goBack = () => {
    navigate("/surveys")
  }

  const simulateProgress = (target, increment) => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= target) {
            clearInterval(interval)
            resolve()
            return target
          }
          return Math.min(prev + increment, target)
        })
      }, 50)
    })
  }

  const exportToPDF = async () => {
    setLoadingExport(true)
    setProgress(0)

    try {
      const permission = await checkPermission("import_survey")
      if (!permission) {
        dispatch(
          showUpdatedToasterMessage({
            message: Denied_Msg,
            type: "error"
          })
        )
        setLoadingExport(false)
        return
      }

      const response = await SurveyAPI.markAsExport(id)
      if (response.status === true) {
        const content = document.getElementById("previewPdf")
        if (content) {
          const clonedContent = content.cloneNode(true)
          const elementsToHide = clonedContent.querySelectorAll(".hide-in-pdf")
          const elementFormLogo = clonedContent.querySelectorAll(".remove-item")
          const userDataHeading = clonedContent.querySelectorAll(".user-data small")
          const HeaderRow = clonedContent.querySelectorAll(".text-input")
          const FileInputRow = clonedContent.querySelectorAll(".file-form")

          HeaderRow.forEach((element) => {
            if (element.tagName.toLowerCase() === "input") {
              // Create a new p tag
              const pTag = document.createElement("p")
              pTag.textContent = element.value || element.placeholder || ""

              pTag.className = element.className // Copy the classes
              pTag.id = element.id // Copy the id if it exists

              // Copy the inline styles (if necessary)
              pTag.style.cssText = element.style.cssText

              // Replace the input element with the new p tag
              element.replaceWith(pTag)
            }
          })

          const textareaRow = clonedContent.querySelectorAll("textarea")
          textareaRow.forEach((element) => {
            if (element.tagName.toLowerCase() === "textarea") {
              const pTag = document.createElement("p")
              pTag.textContent = element.textContent || element.placeholder || ""
              pTag.className = element.className
              pTag.id = element.id
              pTag.style.cssText = element.style.cssText
              element.replaceWith(pTag)
            }
          })
          elementsToHide.forEach((element) => element.remove())
          elementFormLogo.forEach((element) => element.remove())

          const dateRow = clonedContent.querySelectorAll(".date-input")
          dateRow.forEach((element) => {
            element.style.paddingLeft = "40px"
          })

          userDataHeading.forEach((element) => {
            element.style.minWidth = "150px"
          })

          FileInputRow.forEach((element) => {
            element.style.paddingTop = "5px"
            element.style.lineHeight = "40px"
            element.style.display = "flex"
          })

          const opt = {
            // margin: [10, 2],
            filename: "survey_" + id + ".pdf",
            image: { type: "jpeg", quality: 0.98 },
            enableLinks: true,
            html2canvas: {
              scale: 3,
              useCORS: true
            },
            pagebreak: { mode: ["avoid-all"] },
            jsPDF: {
              unit: "pt",
              format: "a4",
              orientation: "portrait"
            }
          }

          const worker = html2pdf().from(clonedContent).set(opt)

          // Simulate progress updates
          await worker.toContainer()
          await simulateProgress(33, 2)

          await worker.toCanvas()
          await simulateProgress(66, 2)

          await worker.toPdf()
          await simulateProgress(100, 2)

          await worker.save()
        } else {
          throw new Error("Content to export not found")
        }
      }
    } catch (error) {
      dispatch(
        showUpdatedToasterMessage({
          message: error.message || "An error occurred during PDF export",
          type: "error"
        })
      )
    } finally {
      setLoadingExport(false)
      setProgress(0) // Reset progress after completion
    }
  }

  const onSubmit = () => {}

  const uploadImage = async () => {}
  const openInNewTab = async (fileData, fileType) => {
    const newWindow = window.open()

    if (fileType === "image") {
      newWindow.document.write(`<img src="${fileData}"/>`)
    } else if (fileType === "pdf") {
      newWindow.document.write(
        `<embed src="http://3.234.59.31:3000/public/assets/images/survey/KLP/163146/name_2_1703142440321.pdf" type="application/pdf" width="100%" height="100%" />`
      )
    } else if (fileType === "text") {
      try {
        let path =
          "http://3.234.59.31:3000/public/assets/images/survey/KLP/163146/name_13_1703150774511.txt"
        const response = await fetch(path)
        if (!response.ok) {
          throw new Error("Network response was not ok.")
        }
        const textContent = await response.text()
        newWindow.document.write(`<pre>${textContent}</pre>`)
      } catch (error) {
        console.error("Error fetching or displaying the file:", error)
        newWindow.document.write(`<p>Error: ${error.message}</p>`)
      }
    }
  }
  const onConfirmAction = () => {
    if (modal.action == "refuseSurvey") {
      markAsNotApprove()
    }
    if (modal.action == "approveSurvey") {
      markAsApprove()
    }
  }
  return (
    <>
      {modal.show && (
        <ConfirmModal
          {...modal}
          onActionCallback={onConfirmAction}
          onHide={() => {
            setModal((preveState) => ({
              ...preveState,
              show: false,
              title: "Delete Assigned Surveys",
              description: "Are you sure you want to delete assigned survey?",
              actionBtnText: "Delete Instruction",
              action: "DeleteInstruction"
            }))
          }}
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <main className={`${isActive && "active"}`}>
          <section className="dashboard">
            <div className="survey-questions" id="previewPdf">
              <div className="container-fluid p-0">
                <div className="row g-0 justify-content-center">
                  <div className="col-md-12">
                    <div className="survey-header">
                      <div className="survey-title">
                        <h5>Survey Preview</h5>
                      </div>
                    </div>
                    <div className="user-detail-card mb-20 single-column-detail">
                      <div className="user-detail-wrapper pdf-wrapper">
                        <div className="user-data">
                          <small>Client</small>
                          <span>:</span>
                          <h6>{surveyDetail?.client_name}</h6>
                        </div>
                        <div className="user-data">
                          <small>Round</small>
                          <span>:</span>
                          <h6>{surveyDetail?.round_name}</h6>
                        </div>
                        <div className="user-data">
                          <small>Site Location</small>
                          <span>:</span>
                          <h6>{surveyDetail?.site_location}</h6>
                        </div>
                        <div className="user-data">
                          <small>Schedule Date</small>
                          <span>:</span>
                          <h6>
                            {surveyDetail?.schedule_date
                              ? moment(surveyDetail?.schedule_date).format("MM-DD-YYYY")
                              : null}
                          </h6>
                        </div>
                        {surveyDetail.status == "approved" ? (
                          <div className="user-data">
                            <small>Approved Date</small>
                            <span>:</span>
                            <h6>
                              {surveyDetail?.approved_date
                                ? moment(surveyDetail?.approved_date).format("MM-DD-YYYY")
                                : null}
                            </h6>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="user-data">
                          <small>Project</small>
                          <span>:</span>
                          <h6>{surveyDetail?.project_name}</h6>
                        </div>
                        <div className="user-data">
                          <small>Site</small>
                          <span>:</span>
                          <h6>{surveyDetail?.site_name}</h6>
                        </div>
                        <div className="user-data">
                          <small>FieldRep</small>
                          <span>:</span>
                          <h6>
                            {surveyDetail?.fieldrep_name ? surveyDetail?.fieldrep_name : " - "}
                          </h6>
                        </div>
                        <div className="user-data">
                          <small>FieldRep Code</small>
                          <span>:</span>
                          <h6>
                            {surveyDetail?.fieldrep_code ? surveyDetail?.fieldrep_code : " - "}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="survey-header">
                      <div className="survey-title">
                        <h5>Fill Survey</h5>
                        <span>
                          Service Key : <strong>{surveyDetail?.service_code || "  -"}</strong>
                        </span>
                      </div>

                      <div className="survey-badge ">
                        <Status status={surveyDetail?.status} />
                      </div>
                    </div>
                    <section className="survey-questions bg-white">
                      <div className="container-fluid p-0">
                        <Form
                          onSubmit={handleSubmit(onSubmit)}
                          autoComplete="off"
                          className="card-form survey-preview-form"
                        >
                          <div className="row g-0 justify-content-center">
                            <div
                              className={`col-md-12 ${
                                surveyDetail?.template_column == 2
                                  ? "double-column-layout"
                                  : "single-column-layout"
                              }`}
                            >
                              <>
                                {surveyData.length < 1 ? (
                                  <p className="text-center m-0 py-5">Nothing to preview</p>
                                ) : (
                                  <>
                                    {surveyData.map((data, index) => {
                                      if (data?.ques_type === DRAGGABLE_FORM_CONSTANTS.HEADER) {
                                        headerRef.current = true
                                        c++
                                      } else {
                                        headerRef.current = false
                                      }
                                      // const uniqueKey = `${data.ques_no}_${data.ques_type}_${index}`;

                                      const uniqueKey = data.ques_no != "name" ? data.ques_no : ""

                                      return (
                                        <>
                                          <div
                                            className={
                                              headerRef.current
                                                ? `question-wrapper single-column-question_${
                                                    c % 2 === 0 ? "0" : "1"
                                                  }`
                                                : `white-wrapper${c % 2 === 0 ? "0" : "1"}`
                                            }
                                          >
                                            {data?.ques_type == DRAGGABLE_FORM_CONSTANTS.HEADER && (
                                              <h5 className="question-title mb-0 col-lg-12">
                                                {" "}
                                                {data.ques}
                                              </h5>
                                            )}

                                            {data?.ques_type ==
                                              DRAGGABLE_FORM_CONSTANTS.TEXTBOX && (
                                              <div className="questions-block" key={uniqueKey}>
                                                <span className="question-label">
                                                  <strong>Q{data.ques_no.split("_")[1]}. </strong>
                                                  {data.ques}
                                                  {data.item.validation.required == 1 ? " *" : ""}
                                                </span>
                                                <CommonTextInput
                                                  {...register(uniqueKey)}
                                                  value={getValues(uniqueKey)}
                                                  className="form-control text-input"
                                                  type="text"
                                                  disabled={true}
                                                  label={false}
                                                  name={uniqueKey}
                                                  placeholder="Enter Your Text"
                                                  loginError={errors}
                                                  file={`false`}
                                                  textarea={`false`}
                                                  setIsShow={`false`}
                                                />
                                                {(data.item.validation.numeric == 1 ||
                                                  data.item.validation.numeric == true) && (
                                                  <p>Only numbers allowed</p>
                                                )}
                                              </div>
                                            )}
                                            {data?.ques_type ==
                                              DRAGGABLE_FORM_CONSTANTS.TEXTAREA && (
                                              <div className="questions-block">
                                                <span className="question-label">
                                                  {" "}
                                                  <strong>Q{data.ques_no.split("_")[1]}. </strong>
                                                  {data.ques}
                                                  {data.item.validation.required == 1 ? " *" : ""}
                                                </span>
                                                <CommonTextInput
                                                  {...register(uniqueKey)}
                                                  value={getValues(uniqueKey)}
                                                  className="form-control"
                                                  type="textarea"
                                                  label={false}
                                                  disabled={true}
                                                  name={uniqueKey}
                                                  placeholder="Enter Your Text"
                                                  loginError={errors}
                                                  file={`false`}
                                                  textarea={`true`}
                                                  setIsShow={`false`}
                                                />
                                              </div>
                                            )}
                                            {data?.ques_type ===
                                              DRAGGABLE_FORM_CONSTANTS.CHECKBOX && (
                                              <div className="questions-block" key={uniqueKey}>
                                                <div className="field-rep-block checkbox-list">
                                                  <CheckBox
                                                    label={false}
                                                    disabled={true}
                                                    checked={
                                                      getValues(uniqueKey) === "Yes" ||
                                                      data?.ans === "1"
                                                    }
                                                    onChange={(e) => {
                                                      const value = e.target.checked ? "Yes" : "No"
                                                      setValue(uniqueKey, value)
                                                    }}
                                                  />
                                                </div>
                                                <span className="question-label ms-4">
                                                  <strong>Q{data?.ques_no?.split("_")[1]}. </strong>
                                                  {data?.ques}
                                                  {data?.item?.validation?.required == 1
                                                    ? " *"
                                                    : ""}
                                                </span>
                                                {errors[uniqueKey] && (
                                                  <p className="error">
                                                    {errors[uniqueKey].message}
                                                  </p>
                                                )}
                                              </div>
                                            )}

                                            {data?.ques_type ==
                                              DRAGGABLE_FORM_CONSTANTS.CHECKBOXGRP && (
                                              <div className="questions-block">
                                                <span className="question-label">
                                                  {" "}
                                                  <strong>Q{data.ques_no.split("_")[1]}. </strong>
                                                  {data.ques}
                                                  {data.item.validation.required == 1 ? " *" : ""}
                                                </span>
                                                <div className="field-rep-block checkbox-list preview-checkbox-grp">
                                                  {data?.item?.options?.map((ele, i) => {
                                                    return (
                                                      <div className="field-radio-block">
                                                        <label className="checkbox checkbox-outline-primary mb-0">
                                                          <input
                                                            type="checkbox"
                                                            disabled={true}
                                                            checked={
                                                              getValues(uniqueKey)?.includes(
                                                                ele.exported_value
                                                              ) || false
                                                            }
                                                          />
                                                          <span>{ele.display_value}</span>
                                                          <span className="checkmark"></span>
                                                        </label>
                                                      </div>
                                                    )
                                                  })}
                                                </div>
                                                {errors[uniqueKey] && (
                                                  <p className="error">
                                                    {errors[uniqueKey].message}
                                                  </p>
                                                )}
                                              </div>
                                            )}
                                            {data?.ques_type == DRAGGABLE_FORM_CONSTANTS.SELECT && (
                                              <div className="questions-block">
                                                <span className="question-label">
                                                  <strong>Q{data.ques_no.split("_")[1]}. </strong>
                                                  {data.ques}
                                                  {data.item.validation.required == 1 ? " *" : ""}
                                                </span>
                                                <select
                                                  className="form-select nice-select-form bg-white"
                                                  {...register(uniqueKey)}
                                                  disabled={true}
                                                  onChange={(e) => {
                                                    const selectedValue = e.target.value
                                                    setValue(uniqueKey, selectedValue)
                                                  }}
                                                >
                                                  {data?.item?.options?.map((itm, i) => (
                                                    <option
                                                      key={i}
                                                      value={itm.exported_value}
                                                      selected={
                                                        getValues(uniqueKey) == itm.display_value
                                                      }
                                                    >
                                                      {itm.display_value}
                                                    </option>
                                                  ))}
                                                </select>
                                                {errors[uniqueKey] && (
                                                  <p className="error">
                                                    {errors[uniqueKey].message}
                                                  </p>
                                                )}
                                              </div>
                                            )}
                                            {data?.ques_type == DRAGGABLE_FORM_CONSTANTS.RADIO && (
                                              <div className="questions-block">
                                                <span className="question-label">
                                                  <strong>Q{data.ques_no.split("_")[1]}. </strong>{" "}
                                                  {data.ques}
                                                  {data.item.validation.required == 1 ? " *" : ""}
                                                </span>
                                                <div className="preview-radio-grp">
                                                  {data?.item?.options?.map((ele, i) => {
                                                    return (
                                                      <>
                                                        <div className="field-radio-block" key={i}>
                                                          <label className="radio radio-outline-primary field-radio mb-0">
                                                            <input
                                                              type="radio"
                                                              disabled={true}
                                                              name={uniqueKey}
                                                              checked={
                                                                getValues(uniqueKey) ===
                                                                ele.exported_value
                                                              }
                                                              onChange={() =>
                                                                setValue(
                                                                  uniqueKey,
                                                                  ele.exported_value
                                                                )
                                                              }
                                                            />
                                                            <span>{ele.display_value}</span>
                                                            <span className="checkmark"></span>
                                                          </label>
                                                          {errors[uniqueKey] && (
                                                            <p className="error">
                                                              {errors[uniqueKey].message}
                                                            </p>
                                                          )}
                                                        </div>
                                                      </>
                                                    )
                                                  })}
                                                </div>
                                              </div>
                                            )}
                                            {data?.ques_type == DRAGGABLE_FORM_CONSTANTS.DATE && (
                                              <div className="questions-block">
                                                <span className="question-label">
                                                  <strong>Q{data.ques_no.split("_")[1]}. </strong>{" "}
                                                  {data.ques}
                                                  {data.item.validation.required == 1 ? " *" : ""}
                                                </span>
                                                <div className="input-group date-picker">
                                                  <FormFieldDatePickers
                                                    label={false}
                                                    name={uniqueKey}
                                                    disabled={true}
                                                    register={register}
                                                    control={control}
                                                    placeholder="Select date"
                                                    // minDate={today}
                                                    required
                                                    className="w-100 form-control date-input"
                                                  />
                                                </div>
                                              </div>
                                            )}

                                            {data?.ques_type == DRAGGABLE_FORM_CONSTANTS.FILE && (
                                              <div className="questions-block">
                                                <span className="question-label">
                                                  {" "}
                                                  <strong>
                                                    Q{data.ques_no.split("_")[1]}.{" "}
                                                  </strong>{" "}
                                                  {data.ques}
                                                  {data.item.validation.required == 1 ? " *" : ""}
                                                </span>

                                                <div className="input-group custom-file-button">
                                                  <CommonTextInputExport
                                                    type="file"
                                                    disabled={true}
                                                    name={uniqueKey}
                                                    label={false}
                                                    placeholder="Choose File"
                                                    className="file-form"
                                                    loginError={errors}
                                                    file={`true`}
                                                    textarea={`false`}
                                                    path={getValues(uniqueKey)}
                                                    actualPath={data?.actual_path}
                                                    accept={data?.item?.accept}
                                                    filesallow={data?.item?.accept}
                                                    id="inputGroupFile"
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </>
                                      )
                                    })}
                                  </>
                                )}
                              </>

                              <div className={`template-button-block bg-white hide-in-pdf`}>
                                <div className="d-flex w-100">
                                  {surveyDetail?.status == "reported" && (
                                    <div className="conditionalBtns me-auto">
                                      {/* <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => navigate(`/surveys/preview/edit/${id}`)}
                                      >
                                        
                                      </button> */}
                                      <Button
                                        className="btn save-btn m-0"
                                        onClick={async () => {
                                          const permission = await checkPermission("edit_survey")
                                          if (!permission) {
                                            dispatch(
                                              showUpdatedToasterMessage({
                                                message: Denied_Msg,
                                                type: "error"
                                              })
                                            )
                                            return
                                          }
                                          navigate(`/surveys/preview/edit/${id}/${status}`)
                                        }}
                                        // to={`/surveys/preview/edit/${id}/${status}`}
                                        state={{
                                          previewstatus: surveyDetail?.status
                                        }}
                                      >
                                        Edit Survey
                                      </Button>
                                      <button
                                        type="button"
                                        className="btn btn-danger mx-4 font-14 fw-bold"
                                        onClick={async () => {
                                          const permission = await checkPermission("edit_survey")
                                          if (!permission) {
                                            dispatch(
                                              showUpdatedToasterMessage({
                                                message: Denied_Msg,
                                                type: "error"
                                              })
                                            )
                                            return
                                          }
                                          setModal({
                                            ...modal,
                                            show: true,
                                            title: "Refuse Survey",
                                            description:
                                              "Do you really want to refuse this survey?",
                                            action: "refuseSurvey"
                                          })
                                        }}
                                      >
                                        Mark As Not Approved
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-success"
                                        onClick={async () => {
                                          const permission = await checkPermission("edit_survey")
                                          if (!permission) {
                                            dispatch(
                                              showUpdatedToasterMessage({
                                                message: Denied_Msg,
                                                type: "error"
                                              })
                                            )
                                            return
                                          }
                                          setModal({
                                            ...modal,
                                            show: true,
                                            title: "Approve Survey",
                                            description:
                                              "Do you really want to approve this survey?",
                                            action: "approveSurvey"
                                          })
                                        }}
                                      >
                                        Approve
                                      </button>
                                    </div>
                                  )}
                                  <button
                                    type="button"
                                    onClick={exportToPDF}
                                    disabled={loadingExport}
                                    className="btn save-btn"
                                  >
                                    {loadingExport ? `Exporting...${progress}%` : "Export"}
                                  </button>
                                  {/* <button
                                    type="button"
                                    className="btn save-btn"
                                    onClick={exportToPDF}
                                  >
                                    Export
                                  </button> */}
                                  <button className="btn cancel-btn" onClick={goBack}>
                                    cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
    </>
  )
}

export default PreviewSurvey
