import React, { useEffect, useState } from "react";
import toastErrorIcon from "../../assets/images/toast-error.svg";
import { ToastContainer } from "react-bootstrap";
import CloseSvg from "../../assets/images/close.svg";
import Toast from "react-bootstrap/Toast";

const ErrorToaster = ({
  type,
  text = "",

}) => {


  return (
    <ToastContainer>
      <Toast
      >
        <Toast.Body>
          <div className="toast-wrapper">
              <>
                <div className="toast-error-bg">
                  <span>
                    <img src={toastErrorIcon} alt="error" />
                  </span>
                </div>
                <div className="toast-content">
                  <h6>{type}!</h6>
                  <p>{text}</p>
                </div>
                <img
                  src={CloseSvg}
                  className="toast-close"
                />
              </>
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ErrorToaster;

