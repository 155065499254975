import { yupResolver } from "@hookform/resolvers/yup"
import React, { Fragment, useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import * as yup from "yup"
import * as AuthAPI from "../api/AuthApi"
import * as UserAPI from "../api/UserAPI.js"
import * as NotificationsAPI from "../api/NotificationsAPI.js"
import ArsIcon from "../assets/images/ars-icon.svg"
import BellIcon from "../assets/images/bell-icon.svg"
import HamburgerIcon from "../assets/images/hamburger.svg"
import toastSuccessIcon from "../assets/images/toast-success.svg"
import CloseSvg from "../assets/images/close.svg"
import defaultavatar from "../assets/images/user-thumbnail.png"
import CommonTextInput from "../components/Input/CommonTextInput.js"
import SiteSidebar from "../interface/Sidebar.js"
import { resetAuthData } from "../store/slices/authUser/authUserSlice"
import { showUpdatedToasterMessage } from "../store/slices/toaster/toasterslice.js"
import { requestPermission, onMessageListener } from "../common/firebase.js"
import { toast } from "react-toastify"
import { updateCount } from "../store/slices/notificationcount/notificationCountSlice.js"

const SiteHeader = ({ isActive, toggleActive, children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const count = useSelector((state) => state?.ars?.notificationCountSlice?.count)
  console.log("🚀 ~ SiteHeader ~ count:", count)
  const [showPasswordText, setShowPasswordText] = useState([])
  const authSelector = useSelector((state) => state.ars.authUserReducer)
  console.log("🚀 ~ SiteHeader ~ authSelector:", authSelector)
  const [notification, setNotification] = useState({ title: "", body: "" })

 
  useEffect(() => {
    // Check if service worker is supported by the browser
    if ("serviceWorker" in navigator) {
      // Register the service worker
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js") // URL to your service worker file
        .then((registration) => {
          console.log("Service Worker registered successfully.", registration)
        })
        .catch((error) => {
          console.error("Error registering Service Worker.", error)
        })
    } else {
      console.log("Service Worker is not supported in this browser.")
    }
    requestPermission()?.then((fcmToken) => {
      console.log("🚀 ~ requestPermission ~ fcmToken:", fcmToken)
      // dispatch(addAuthData({ ...authSelector, fcm_token: fcmToken }));
    })
  }, [])
  onMessageListener().then((payload) => {
    console.log("onmessage listener")
    setNotification({
      title: payload?.notification?.title,
      body: payload?.notification?.body
    })

    getNotificationCount()
    // toast notification
    const renderNotification = (payload) => {
      const { title, body } = payload.notification
      return dispatch(
        showUpdatedToasterMessage({
          message: body,
          title: title,
          type: "notify"
        })
      )

      // return (
      //   // <Fragment>
      //   //   <h3 className="text-body mb-0">{title}</h3>
      //   //   <div>{body}</div>
      //   // </Fragment>
      //   <div className="toast-container">
      //     <div className="toast show">
      //       <div className="toast-body">
      //         <div className="toast-wrapper p-0">
      //           <span className="primary">
      //             <svg
      //               width="16"
      //               height="16"
      //               viewBox="0 0 16 16"
      //               fill="#333333"
      //               xmlns="http://www.w3.org/2000/svg"
      //             >
      //               <path
      //                 d="M15.2667 4.99C14.207 2.06 12.2838 0 10.5862 0C10.3213 0 10.1054 0.05 9.88955 0.11C9.40874 0.27 8.98681 0.65 8.72187 1.14C5.52303 4.83 1.10745 6.51 1.05839 6.51C-0.00135174 6.89 -0.325161 8.62 0.361707 10.52C0.950451 12.15 2.01019 13.18 2.97181 13.18C3.07974 13.18 3.23674 13.18 3.34468 13.13C3.45261 13.08 4.04136 12.97 5.1011 12.8L6.16084 15.08C6.2099 15.19 6.31783 15.3 6.48465 15.35L8.192 15.95C8.24107 15.95 8.29994 16 8.349 16C8.45694 16 8.56487 15.95 8.67281 15.89C8.82981 15.78 8.88868 15.56 8.88868 15.4L8.51581 12.31C9.8503 12.2 11.332 12.15 12.8235 12.26H12.9314C13.1473 12.31 13.3533 12.37 13.5692 12.37C13.8341 12.37 14.05 12.32 14.2659 12.26C15.1686 11.93 15.8064 10.9 15.9732 9.44C16.0812 8.14 15.8653 6.51 15.2766 4.99H15.2667ZM7.01451 14.37L6.21971 12.58L7.44626 12.42L7.7112 14.59L7.01451 14.37ZM10.8512 9.17C10.4783 8.57 10.1054 7.87 9.79142 7C9.52649 6.19 9.31062 5.43 9.26155 4.67C9.41855 4.67 9.63443 4.83 9.8503 5C10.2232 5.38 10.596 5.98 10.8119 6.63C11.2338 7.88 11.1357 8.85 10.861 9.18L10.8512 9.17ZM3.07974 12.09H3.03068C2.65781 12.2 1.863 11.49 1.32332 10.14C0.842515 8.78 1.05839 7.65 1.37238 7.54C1.52938 7.49 4.99316 6.18 8.13313 3.26C8.08407 4.51 8.29013 5.97 8.77094 7.38C9.35968 8.95 10.1545 10.25 11.0572 11.18C6.90658 11.13 3.23674 12.05 3.06993 12.1L3.07974 12.09ZM14.8448 9.33C14.7369 10.36 14.364 11.01 13.8341 11.23C13.7262 11.28 13.6183 11.28 13.5103 11.28C12.9216 11.28 12.2347 10.85 11.489 9.98C12.2347 9.33 12.3917 7.86 11.8128 6.24C11.489 5.43 11.067 4.72 10.5862 4.23C10.1643 3.8 9.68349 3.58 9.20268 3.58C9.20268 3.36 9.20268 3.2 9.25174 3.04C9.35968 2.01 9.73255 1.36 10.2624 1.14C10.3704 1.09 10.4783 1.09 10.5862 1.09C11.646 1.09 13.3043 2.72 14.2561 5.37C14.7859 6.73 15.0018 8.19 14.8448 9.33Z"
      //                 fill="#fff"
      //               />
      //             </svg>
      //           </span>
      //           <div className="toast-content">
      //             <h6>{title}</h6>
      //             <p>{body}</p>
      //           </div>
      //           <img src={CloseSvg} alt="success" className="ms-auto" />
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // );
    }
    toast(renderNotification(payload))
    // fetchNotifications();
    console.log("Fetch notifications...")
    // setNotificationCount(notificationCount + 1);
  })

  const getNotificationCount = async () => {
    console.log("authSelector?.user?.user_type")
    try {
      const res = await NotificationsAPI.getNotificationCount(authSelector?.user?.user_type)
      if (res) {
        console.log("🚀 ~ getNotificationCount ~ res:", res)
        dispatch(updateCount({ count: res.data.affectedRows }))
      }
    } catch (e) {}
  }
  const ChangePasswordSchema = yup.object().shape({
    password: yup.string().trim().required("Current Password is required"),
    new_password: yup
      .string()
      .required("New Password is required")
      .min(8, "New Password must be at least 8 characters")
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])([A-Za-z\d]|[^A-Za-z0-9])+$/,
        "New Password must contain at least one uppercase letter, one digit, and one special character"
      )
      .test(
        "not-same-as-old-password",
        "New password must be different from Current Password",
        function (value) {
          return value !== this.parent.password
        }
      ),

    confirm_password: yup
      .string()
      .required("Confirm password is required")

      .oneOf([yup.ref("new_password"), null], "Confirm Passwords must match with New password")
  })

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset: changePasswordReset,
    formState: { errors: changePasswordError },
    setError,
    setFocus
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      password: "",
      new_password: "",
      confirm_password: ""
    },
    resolver: yupResolver(ChangePasswordSchema)
  })
  const modalClose = () => {
    setChangePasswordModalShow(false)
  }
  const [changePasswordModalShow, setChangePasswordModalShow] = useState(false)
  const modalChangePasswordShow = () => {
    changePasswordReset()
    setChangePasswordModalShow(true)
  }
  const changePassword = async (formdata) => {
    console.log("🚀 ~ changePassword ~ formdata:", formdata)
    const payload = new FormData()
    payload.append("password", formdata.new_password)
    payload.append("current_password", formdata.password)
    try {
      let response
      if (authSelector?.user?.user_type == "F") {
        response = await UserAPI.fieldRepPassword(authSelector?.user?.id, {
          password: formdata.new_password,
          confirm_password: formdata.confirm_password,
          current_password: formdata?.password,
          email: authSelector?.user?.email
        })
      } else {
        response = await UserAPI.userUpdatePassword(authSelector?.user?.id, {
          password: formdata.new_password,
          confirm_password: formdata.confirm_password,
          current_password: formdata?.password
        })
      }
      if (response.status == true) {
        dispatch(
          showUpdatedToasterMessage({
            message: "Password updated successfully",
            type: "success"
          })
        )
        changePasswordReset()
        setChangePasswordModalShow(false)
      } else if (response?.error?.code === 422) {
        dispatch(
          showUpdatedToasterMessage({
            message: response?.error?.additional_info?.password,
            type: "Error"
          })
        )
      }
    } catch (e) {
      dispatch(
        showUpdatedToasterMessage({
          message: "Unable to update password",
          type: "danger"
        })
      )
    }
  }

  const handleshowPassword = (id) => {
    if (showPasswordText.includes(id)) {
      setShowPasswordText((prev) => prev.filter((value) => value !== id))
    } else {
      setShowPasswordText((prev) => [...prev, id])
    }
  }
  const handleLogout = async () => {
    const headers = {
      headers: {
        "Content-Type": "multipart/form-data",
        accesscode: `${authSelector.accesscode}`,
        Authorization: `Bearer ${authSelector.access_token}`
      }
    }
    const data = { refreshToken: authSelector.access_token }
    try {
      const response = await AuthAPI.logout(data, headers)
      if (response.status == true) {
        dispatch(resetAuthData())
        localStorage.clear()
        navigate("/login", { replace: true })
        dispatch(
          showUpdatedToasterMessage({
            message: "Logout successfully",
            type: "success"
          })
        )
      }
    } catch (error) {
      dispatch(
        showUpdatedToasterMessage({
          message: "Unable to logout",
          type: "danger"
        })
      )
    }
  }
  return (
    <>
      <section className="admin-wrapper">
        <header className={`${isActive ? "active" : ""}`}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="header-wrapper">
                  <div className="logo-toggle-menu">
                    <div className="mob-logo">
                      <img src={ArsIcon} alt="ars-logo" />
                    </div>
                    <div className="toggle-mob">
                      <img src={HamburgerIcon} onClick={toggleActive} alt="profile" />
                    </div>
                  </div>
                  <div className="search-profile-block">
                    <div className="user-profile">
                      <Link
                        to={
                          authSelector?.user?.user_type === "S"
                            ? "/sa/notifications"
                            : authSelector?.user?.user_type === "F"
                            ? "/fieldrep/notifications"
                            : "/notifications"
                        }
                      >
                        <button className="full-screen-btn position-relative">
                          <img src={BellIcon} alt="bell" />
                          <span>{3}</span>
                        </button>
                      </Link>

                      <div className="dropdown-center dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="user-dropdown-content">
                            <span className="user-img">
                              {/* process.env.REACT_APP_RELATIVE_URL +
                                  "uploads/profile/" + */}
                              <img
                                src={
                                  authSelector.profile?.profile_pic
                                    ? authSelector.profile?.profile_pic
                                    : defaultavatar
                                }
                                alt="user"
                              />
                            </span>
                            <div className="user-title">
                              <h6>
                                {/* {authSelector?.user?.user_type === "A" ? authSelector.profile?.name : authSelector?.fieldrep?.first_name + " " + authSelector?.fieldrep?.last_name} */}
                                {/* {authSelector?.user?.user_type === "A" ||
                                authSelector?.user?.user_type === "AA"
                                  ? authSelector.profile?.name
                                  : authSelector?.user?.user_type === "S" ||
                                    authSelector?.user?.user_type === "SU"
                                  ? authSelector.profile?.name
                                  : authSelector?.fieldrep?.first_name +
                                    " " +
                                    authSelector?.fieldrep?.last_name} */}
                                    {
                                      authSelector?.user?.user_type == "F" ? 
                                      authSelector?.fieldrep?.first_name +
                                    " " +
                                    authSelector?.fieldrep?.last_name :
                                    authSelector.profile?.name
                                    }
                              </h6>
                            </div>
                          </div>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              className="dropdown-item"
                              to={`${
                                authSelector?.user?.user_type === "A" ||
                                authSelector?.user?.user_type === "AA"
                                  ? `/profile`
                                  : authSelector?.user?.user_type === "F"
                                  ? `/fieldrep/profile`
                                  : authSelector?.user?.user_type === "S" ||
                                    authSelector?.user?.user_type === "SU"
                                  ? `/sa/profile`
                                  : ""
                              }`}
                            >
                              <svg
                                width="14"
                                height="16"
                                viewBox="0 0 14 16"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M12.7339 9.9171C11.9245 8.80323 10.804 7.95839 9.49413 7.47419L9.37921 7.43194L9.47876 7.36194C10.0049 6.99129 10.4419 6.50129 10.7419 5.94516C11.0522 5.37032 11.2159 4.72226 11.2159 4.07097C11.2159 1.82613 9.32476 0 7 0C4.67524 0 2.78376 1.82613 2.78376 4.07097C2.78376 4.72226 2.94779 5.37032 3.2578 5.94516C3.55813 6.50129 3.99475 6.99129 4.52124 7.36194L4.62045 7.43194L4.50587 7.47419C3.19567 7.95839 2.07555 8.80323 1.26577 9.9171C0.437625 11.0568 0 12.3958 0 13.7897C0 15.0084 1.02658 16 2.28868 16H11.711C12.9731 16 14 15.0084 14 13.7897C14 12.3958 13.562 11.0568 12.7339 9.9171ZM3.93529 4.07097C3.93529 2.43903 5.30996 1.11161 7 1.11161C8.69004 1.11161 10.0647 2.43903 10.0647 4.07097C10.0647 5.7029 8.69004 7.03032 7 7.03032C5.30996 7.03032 3.93529 5.7029 3.93529 4.07097ZM11.711 14.8881H2.28868C1.66164 14.8881 1.15119 14.3955 1.15119 13.7897C1.15119 10.6755 3.77494 8.14194 7 8.14194C10.2251 8.14194 12.8488 10.6755 12.8488 13.7897C12.8488 14.3955 12.3384 14.8881 11.711 14.8881Z"></path>
                              </svg>
                              My Profile
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" onClick={modalChangePasswordShow}>
                              <svg
                                width="12"
                                height="16"
                                viewBox="0 0 12 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M10.4647 5.96356H10.0147V4.01988C10.0147 1.80232 8.21471 0 6 0C3.78529 0 1.98529 1.80232 1.98529 4.01988V5.96356H1.53529C0.688235 5.96356 0 6.65268 0 7.50083V14.4627C0 15.3109 0.688235 16 1.53529 16H10.4647C11.3118 16 12 15.3109 12 14.4627V7.50083C12 6.65268 11.3118 5.96356 10.4647 5.96356ZM3.06176 5.96356V4.01988C3.06176 2.40309 4.37647 1.07786 6 1.07786C7.62353 1.07786 8.93824 2.39426 8.93824 4.01988V5.96356H3.06176ZM1.07647 7.50083C1.07647 7.25345 1.27941 7.05025 1.52647 7.05025H10.4559C10.7029 7.05025 10.9059 7.25345 10.9059 7.50083V14.4627C10.9059 14.7101 10.7029 14.9133 10.4559 14.9133H1.53529C1.28824 14.9133 1.08529 14.7101 1.08529 14.4627V7.50083H1.07647Z"></path>
                                <path d="M5.46176 11.238V12.7929C5.46176 13.0933 5.7 13.3319 6 13.3319C6.3 13.3319 6.53823 13.0933 6.53823 12.7929V11.238C7.04118 11.0171 7.36765 10.5312 7.36765 9.98343C7.36765 9.23247 6.75 8.61403 6 8.61403C5.25 8.61403 4.63235 9.23247 4.63235 9.98343C4.63235 10.5312 4.95882 11.026 5.46176 11.238ZM5.71765 9.98343C5.71765 9.82441 5.85 9.69188 6.00882 9.69188C6.16765 9.69188 6.3 9.82441 6.3 9.98343C6.3 10.1425 6.16765 10.275 6.00882 10.275C5.85 10.275 5.71765 10.1425 5.71765 9.98343Z"></path>
                              </svg>
                              Change Password
                            </Link>
                          </li>
                          <li onClick={handleLogout} className="cursor-pointer">
                            <a className="dropdown-item">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M13.2964 0H7.17778C6.83094 0 6.53746 0.284129 6.53746 0.63929C6.53746 0.994451 6.83094 1.27858 7.17778 1.27858H13.2964C14.0879 1.27858 14.7371 1.91787 14.7371 2.7081V13.2919C14.7371 14.0733 14.0879 14.7214 13.2964 14.7214H7.17778C6.83094 14.7214 6.53746 15.0055 6.53746 15.3607C6.53746 15.7159 6.83094 16 7.17778 16H13.2964C14.7905 16 16 14.7925 16 13.3008V2.69922C16 1.20755 14.7905 0 13.2964 0Z"></path>
                                <path d="M2.36647 8.9323L2.07299 8.62153H10.4239C10.7707 8.62153 11.0553 8.3374 11.0553 7.98224C11.0553 7.62708 10.7707 7.34295 10.4239 7.34295H2.09078L2.38426 7.03219L4.8833 4.3596C4.99891 4.24417 5.06116 4.07547 5.05227 3.90677C5.04338 3.74695 4.98112 3.57825 4.85662 3.4717C4.741 3.35627 4.58092 3.303 4.42084 3.303C4.25187 3.303 4.08289 3.37403 3.95839 3.49833L0.205388 7.52053C0.125347 7.60044 0.0719873 7.67148 0.0364138 7.76914C0.0364138 7.7869 0.0275205 7.81354 0.0275205 7.84906V7.89345C0.0275205 7.89345 0.00973375 7.93785 0.000840384 7.96448C-0.00805298 8.1687 0.0542006 8.31964 0.160921 8.43507L3.95839 12.4839C4.074 12.6082 4.25187 12.6881 4.42084 12.6881C4.58092 12.6881 4.741 12.6348 4.85662 12.5194C4.98112 12.4129 5.04338 12.2442 5.05227 12.0843C5.06116 11.9156 4.99891 11.7469 4.8833 11.6315L2.36647 8.94118V8.9323Z"></path>
                              </svg>
                              Logout
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <Modal show={changePasswordModalShow} onHide={modalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content">
              <Form
                autoComplete="off"
                className="modal-form"
                encType="multipart/form-data"
                onSubmit={handleSubmit(changePassword)}
              >
                <div className="form-block add-resource-modal-form">
                  <CommonTextInput
                    {...register("password")}
                    name="password"
                    type={showPasswordText.includes("password") ? "text" : "password"}
                    label="Current Password*"
                    placeholder="Enter password"
                    loginError={changePasswordError}
                    file={`false`}
                    textarea={`false`}
                    setIsShow={`false`}
                    showPassword={true}
                    handleshowPassword={handleshowPassword}
                    id={`password`}
                  />
                </div>
                <div className="form-block add-resource-modal-form">
                  <CommonTextInput
                    {...register("new_password")}
                    name="new_password"
                    type={showPasswordText.includes("new_password") ? "text" : "password"}
                    label="New Password*"
                    placeholder="Enter new password"
                    loginError={changePasswordError}
                    file={`false`}
                    textarea={`false`}
                    setIsShow={`false`}
                    showPassword={true}
                    handleshowPassword={handleshowPassword}
                    id={`new_password`}
                  />
                </div>
                <div className="form-block add-resource-modal-form">
                  <CommonTextInput
                    {...register("confirm_password")}
                    name="confirm_password"
                    type={showPasswordText.includes("confirm_password") ? "text" : "password"}
                    label="Confirm Password*"
                    placeholder="Enter confirm password"
                    loginError={changePasswordError}
                    file={`false`}
                    textarea={`false`}
                    setIsShow={`false`}
                    showPassword={true}
                    handleshowPassword={handleshowPassword}
                    id={`confirm_password`}
                  />
                </div>
                <div className="modal-button-block">
                  <Button className="submit-btn" type="Submit">
                    Submit
                  </Button>
                  <Button className="cancel-btn" onClick={modalClose}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        <SiteSidebar isActive={isActive} toggleActive={toggleActive} />
        {children}
      </section>
    </>
  )
}
export default SiteHeader
