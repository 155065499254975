import React, { Fragment, Suspense, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../components/Loader";
import Toaster from "../components/Toaster/Toaster";
import * as AuthApi from "../api/AuthApi";
import * as SuperAdminPermission from "../page/SUPERADMIN/api/authApi";
import InjectProtectedNode from "./injectProtectedNode";
import UnauthorizerNode from "./unauthorizerNode";
import {
  resetAuthData,
  addAuthData,
} from "../store/slices/authUser/authUserSlice";
import { showUpdatedToasterMessage } from "../store/slices/toaster/toasterslice";
import ErrorToaster from "../components/Toaster/ErrorToaster";

const Layout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const authSelector = useSelector((state) => state.ars.authUserReducer);
  const {refreshToken} = authSelector;

  const [authData, setAuthData] = useState(authSelector);
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  axios.interceptors.request.use(
    (req) => {
      req.baseURL = process.env.REACT_APP_API_URL;
      if (authSelector?.access_token) {
        req.headers = {
          "Content-Type": "application/json",

          Authorization: `Bearer ${authSelector.access_token}`,
          accesscode: authSelector.accesscode,
          ...req.headers,
        };
      }
      return req;
    },
    (error) => {
      return error;
    }
  );
  axios.interceptors.response.use(
    async (response) => {
  
      if (response?.data?.error?.code === 401 && response?.data?.error?.message === "Unauthorized") {
       
  
        if (refreshToken) {
          try {
            const res = await AuthApi.requestToken({ refreshToken });
          
  
            if (res?.accessToken) {
              dispatch(addAuthData({
                ...authSelector,
                access_token: res.accessToken,
              }));
  
              const originalRequest = response.config; 
              originalRequest.headers['Authorization'] = `Bearer ${res.accessToken}`; 
              return axios(originalRequest); 
            } else {
             
              toast(<ErrorToaster text={'Your session is expired'} type={'Error'} />, {
                autoClose: false,
                hideProgressBar: true,
                closeButton: false,
                icon: false,
                className: 'error-toaster',
                position: "top-right",
              });
  
              setTimeout(() => {
                localStorage.clear();
                window.location.href = "/login"; 
              }, 1000);
              return response;
            }
          } catch (errors) {
            console.log("🚀 ~ async ~ errors:", errors);
          }
        }
      }
  

      return response;
    },
    (error) => {
      console.log("🚀 ~ error:main index", error);
      return Promise.reject(error);
    }
  );
 
  

  const updateuserPermission = async () => {
    try {
      const res = await AuthApi.updatePermission();
      if (res.status == true) {
        const newData = {
        
          ...authSelector,
          user_permissions: [...res.data.user_permissions],
        };
        dispatch(addAuthData(newData));
      }
    } catch (e) {}
  };
  const updateSuperAdminPermission = async () => {
    
    try {
      const res = await SuperAdminPermission.updatePermissionSa();
      if (res.status == true) {
        const newData = {
          ...authSelector,
          user_permissions: [...res.data.user_permissions],
        };
        dispatch(addAuthData(newData));
      }
    } catch (e) {}
  };
  useEffect(() => {
    if (authSelector?.access_token) {
      if (authSelector?.user?.user_type === "AA") {
        updateuserPermission();
      } else if (authSelector?.user?.user_type === "SU") {
        updateSuperAdminPermission();
      }
    }
  }, [location]);

  return (
    <Fragment>
      {authSelector.auth ? (
        <InjectProtectedNode authSelector={authSelector} />
      ) : (
        <Suspense fallback={<Loader />}>
          <UnauthorizerNode />
        </Suspense>
      )}
      <Toaster />
    </Fragment>
  );
};

export default Layout;
