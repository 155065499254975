import React, { lazy } from "react"
import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"

import PreviewSurvey from "../components/CustomModal/PreviewSurvey"
import PreviewSurveyAddEdit from "../components/CustomModal/PreviewSurveyAddEdit"
import { superadminhost,adminhost,staginghost } from "../config"
const IssueReceived = lazy(() => import("../page/ReportAnIssue/IssueReceived"))
const Login = lazy(() => import("../page/Authentication/Login"))
const SuperAdminLogin = lazy(() => import("../page/Authentication/SuperAdminLogin"))
const ProtectedRoute = lazy(() => import("./ProtectedRoute"))
const ForgotPassword = lazy(() => import("../page/Authentication/ForgotPassword"))
const ResetPassword = lazy(() => {
  return import("../page/Authentication/ResetPassword")
})
 
const MyAccount = lazy(() => import("../page/AccountSettings/MyAccount"))
const Profile = lazy(() => import("../page/MyProfile/MyProfile"))
const Logout = lazy(() => import("../page/Authentication/Logout"))
const ErrorPage = lazy(() => import("../page/ErrorPage"))
const { ErrorPageAuth } = lazy(() => import("../page/ErrorPage"))
const Dashboard = lazy(() => import("../page/Dashboard"))
const FieldrepDashboard = lazy(() => import("../page/FIELDREP/Dashboard"))
const Rounds = lazy(() => import("../page/Rounds/Rounds"))
const Surveys = lazy(() => import("../page/Surveys/Surveys"))
const SurveyTemplates = lazy(() => import("../page/SurveyTemplates/SurveyTemplates"))
const SurveyTag = lazy(() => import("../page/SurveyTemplates/TagSurveyTemplate"))
const SurveyAddEdit = lazy(() => import("../page/SurveyTemplates/AddEditSurveyTemplate"))
const RoundsAddEdit = lazy(() => import("../page/Rounds/AddEditRound"))
const Clients = lazy(() => import("../page/Clients/Clients"))
const ClientsAddEdit = lazy(() => import("../page/Clients/AddEditClient"))
const Chains = lazy(() => import("../page/Chains/Chains"))
const ChainsAddEdit = lazy(() => import("../page/Chains/AddEditChain"))
const Sites = lazy(() => import("../page/Sites/Sites"))
const SitesAddEdit = lazy(() => import("../page/Sites/AddEditSite"))
const Fieldreps = lazy(() => import("../page/Fieldreps/Fieldreps"))
const FieldrepsAddEdit = lazy(() => import("../page/Fieldreps/AddEditFieldrep"))
 
const Projects = lazy(() => import("../page/Projects/Projects"))
const ProjectDashboard = lazy(() => import("../page/Projects/ProjectDashboard"))
const ProjectsAddEdit = lazy(() => import("../page/Projects/AddEditProject"))
const Users = lazy(() => import("../page/Users/Users"))
const UsersAddEdit = lazy(() => import("../page/Users/AddEditUsers"))
const RolesPermission = lazy(() => import("../page/RolesPermissions/RolesPermission"))
const RolesPermissionAddEdit = lazy(() => import("../page/RolesPermissions/AddEditRolePermissions"))
const ExportSurvey = lazy(() => import("../page/ExportSurvey/ExportSurvey"))
const EmailTemplates = lazy(() => import("../page/EmailTemplates/EmailTemplates"))
const EmailTemplatesAddEdit = lazy(() => import("../page/EmailTemplates/AddEditEmailTemplates"))
const Settings = lazy(() => import("../page/Settings/Settings"))
const ReportAnIssue = lazy(() => import("../page/ReportAnIssue/ReportAnIssue"))
const Recruitment = lazy(() => import("../page/Recruitment/Recruitment"))
const Reports = lazy(() => import("../page/Reports/Reports"))
const BroadcastMessages = lazy(() => import("../page/BroadcastMessages/BroadcastMessages"))
const BroadcastReceivedMessages = lazy(() =>
  import("../page/BroadcastMessages/BroadcastReceivedMessages")
)
const Notification = lazy(() => import("../page/Notifications/Notifications"))
const Help = lazy(() => import("../page/Help/Help"))
 
const ClientsImport = lazy(() => import("../page/Import/components/Import/Clients"))
const ProjectsImport = lazy(() => import("../page/Import/components/Import/Projects"))
const ChainsImport = lazy(() => import("../page/Import/components/Import/Chains"))
const RoundsImport = lazy(() => import("../page/Import/components/Import/Rounds"))
const FieldRepsImport = lazy(() => import("../page/Import/components/Import/FieldReps"))
const SitesImport = lazy(() => import("../page/Import/components/Import/Sites"))
const SurveysImport = lazy(() => import("../page/Import/components/Import/Surveys"))
 
const FieldrepOffers = lazy(() => import("../page/FIELDREP/page/FieldrepOffers/FieldrepOffers"))
const Calender = lazy(() => import("../page/FIELDREP/page/Calander/Calender"))
const FieldrepSurveys = lazy(() => import("../page/FIELDREP/page/FieldrepSurveys/FieldrepSurveys"))
const FieldrepSurvey = lazy(() => import("../components/CustomModal/FieldrepSurvey"))
const FieldrepProfile = lazy(() => import("../page/FIELDREP/page/FieldrepProfile/FieldrepProfile"))
const FieldrepReportIssue = lazy(() =>
  import("../page/FIELDREP/page/FieldrepReportIssue/FieldrepReportIssue")
)
const FieldrepResources = lazy(() =>
  import("../page/FIELDREP/page/FieldrepResources/FieldrepResources")
)
const ClientResources = lazy(() =>
  import("../page/FIELDREP/page/FieldrepResources/ClientResource")
)
const EditFieldrepResources = lazy(() =>
  import("../page/FIELDREP/page/FieldrepResources/Components/EditFieldrepResources")
)
const FieldrepHelp = lazy(() => import("../page/FIELDREP/page/FieldrepHelp/FieldrepHelp"))
const FieldrepNotifications = lazy(() =>
  import("../page/FIELDREP/page/FieldrepNotifications/FieldrepNotifications")
)
const FieldrepBroadcastMessages = lazy(() =>
  import("../page/FIELDREP/page/FieldrepBroadcastMessages/FieldrepBroadcastMessages")
)
const SuperAdminDashboard = lazy(() => import("../page/SUPERADMIN/Dashboard"))
const SuperAdminProfile = lazy(() =>
  import("../page/SUPERADMIN/page/SuperAdminProfile/SuperAdminProfile")
)
const SuperAdminInquery = lazy(() =>
  import("../page/SUPERADMIN/page/SuperAdminInquery/SuperAdminInquery")
)
const SuperAdminCutomers = lazy(() =>
  import("../page/SUPERADMIN/page/SuperAdminCustomers/SuperAdminCustomers")
)
const SuperAdminBilling = lazy(() =>
  import("../page/SUPERADMIN/page/SuperAdminBilling/SuperAdminBilling")
)
const SuperAdminBroadcastMessages = lazy(() =>
  import("../page/SUPERADMIN/page/SuperAdminBroadcastMessages/SuperAdminBroadcastMessages")
)
 
const SuperAdminReceivedBroadcastMsg = lazy(() =>
  import("../page/SUPERADMIN/page/SuperAdminBroadcastMessages/SuperAdminReceivedBroadcastMsg")
)
const SuperAdminNotifications = lazy(() =>
  import("../page/SUPERADMIN/page/SuperAdminNotifications/SuperAdminNotifications")
)
const SuperAdminTickets = lazy(() =>
  import("../page/SUPERADMIN/page/SuperAdminTickets/SuperAdminTickets")
)
const SuperAdminReports = lazy(() =>
  import("../page/SUPERADMIN/page/SuperAdminReports/SuperAdminReports")
)
const SuperAdminSettings = lazy(() =>
  import("../page/SUPERADMIN/page/SuperAdminSettings/SuperAdminSettings")
)
const SuperAdminHelp = lazy(() => import("../page/SUPERADMIN/page/SuperAdminHelp/SuperAdminHelp"))
const SuperAdminUsers = lazy(() =>
  import("../page/SUPERADMIN/page/SuperAdminUsers/SuperAdminUsers")
)
const SuperAdminUsersAddEdit = lazy(() =>
  import("../page/SUPERADMIN/page/SuperAdminUsers/AddEditSuperAdminUsers")
)
const SuperAdminRolesPermission = lazy(() =>
  import("../page/SUPERADMIN/page/SuperAdminRolesPermission/SuperAdminRolesPermission")
)
const AddEditSaRolesPermissions = lazy(() =>
  import("../page/SUPERADMIN/page/SuperAdminRolesPermission/AddEditSaRolesPermissions")
)
const hostname = window.location.hostname;

const unauthorizedRoutes = [
  {
    path: "/",
    children: [
      {
        index: true,
        element: <Login />
      },
      {
        path: "/login",
        element: <Login />
      },
      {
        path: "/sa",
        element: <SuperAdminLogin />
      },
      // {
      //   path: "/logout",
      //   element: <Logout />
      // },
      {
        path: "/forgot-password",
        element: <ForgotPassword />
      },
      {
        path: "/reset-password/:token",
        element: <ResetPassword />
      }
    ]
  },
  {
    path: "*",
    element: <Navigate to="/login" replace={true} />
  }
]
const unauthorizedRoutesSa = [
  {
    path: "/",
    children: [
      {
        index: true,
        element: <SuperAdminLogin />
      },
      {
        path: "/login",
        element: <SuperAdminLogin />
      },
      {
        path: "/sa",
        element: <SuperAdminLogin />
      },
      // {
      //   path: "/logout",
      //   element: <Logout />
      // },
      {
        path: "/forgot-password",
        element: <ForgotPassword />
      },
      {
        path: "/reset-password/:token",
        element: <ResetPassword />
      }
    ]
  },
  {
    path: "*",
    element: <Navigate to="/sa" replace={true} />
  }
]
 
const getRoles = (authSelector, isActive) => {
  let arr = []
  if (authSelector?.user?.user_type == "F" && (hostname == adminhost || hostname == 'localhost' || hostname == staginghost)) {
    arr = [
      {
        path: "/",
        element: <ProtectedRoute redirectPath="/login" auth={authSelector.auth} />,
        children: [
          {
            index: true,
            element: <Navigate to="/fieldrep/dashboard" replace={true} />
          },
          {
            path: "/login",
            element: <Navigate to="/fieldrep/dashboard" replace={true} />
          },
          {
            exact: true,
            path: "/fieldrep/dashboard",
            element: <FieldrepDashboard isActive={isActive} />
          },
          {
            exact: true,
            path: "/fieldrep/calendar",
            element: <Calender isActive={isActive} />
          },
          // {
          //   path: "logout",
          //   element: <Logout />
          // },
          {
            path: "/fieldrep/offers",
            children: [
              {
                exact: true,
                index: true,
                element: <FieldrepOffers isActive={isActive} />
              }
            ]
          },
          {
            path: "/fieldrep/surveys",
            children: [
              {
                exact: true,
                index: true,
                element: <FieldrepSurveys isActive={isActive} />
              },
              {
                exact: true,
                path: "preview/edit/:id/:status",
                element: <FieldrepSurvey isActive={isActive} />
              }
            ]
          },
          {
            path: "/fieldrep/profile",
            element: <FieldrepProfile isActive={isActive} />
          },
          {
            path: "/fieldrep/reportIssue",
            element: <FieldrepReportIssue isActive={isActive} />
          },
          // {
          //   path: "/fieldrep/resources",
          //   element: <FieldrepResources isActive={isActive} />,
          // },
          {
            path: "/fieldrep/resources",
            children: [
              {
                index: true,
                element: <FieldrepResources isActive={isActive} />
              },
 
              {
                exact: true,
                path: "edit/:id",
                element: <EditFieldrepResources isActive={isActive} />
              } ,
              {
                exact: true,
                path: "view/:id",
                element: <ClientResources isActive={isActive} />
              }
            ]
          },
          {
            path: "/fieldrep/notifications",
            element: <FieldrepNotifications isActive={isActive} />
          },
          {
            path: "/fieldrep/broadcastMessages",
            element: <FieldrepBroadcastMessages isActive={isActive} />
          },
          {
            path: "/fieldrep/help",
            element: <FieldrepHelp isActive={isActive} />
          }
        ]
      },
      { path: "*", element: <ErrorPage /> }
    ]
  } else if (authSelector?.user?.user_type == "A" && (hostname == adminhost || hostname == 'localhost' || hostname == staginghost)) {
    arr = [
      {
        path: "/",
        element: <ProtectedRoute redirectPath="/login" auth={authSelector.auth} />,
        children: [
          {
            index: true,
            element: <Dashboard isActive={isActive} />
          },
          {
            path: "/login",
            element: <Navigate to="/" replace={true} />
          },
          {
            exact: true,
            path: "dashboard",
            element: <Dashboard isActive={isActive} />
          },
          {
            path: "clients",
            children: [
              {
                exact: true,
                index: true,
                element: <Clients isActive={isActive} />
              },
              {
                exact: true,
                path: "add",
                element: <ClientsAddEdit isActive={isActive} />
              },
              {
                exact: true,
                path: "edit/:id",
                element: <ClientsAddEdit isActive={isActive} />
              }
            ]
          },
          {
            path: "chains",
            children: [
              {
                index: true,
                element: <Chains isActive={isActive} />
              },
              {
                path: "add",
                element: <ChainsAddEdit isActive={isActive} />
              },
              {
                path: "edit/:id",
                element: <ChainsAddEdit isActive={isActive} />
              }
            ]
          },
          {
            path: "surveys",
            children: [
              {
                index: true,
                element: <Surveys isActive={isActive} />
              },
              {
                path: ":round_id",
                element: <Surveys isActive={isActive} />
              },
              {
                path: "projectSurveys/:round_id",
                element: <Surveys isActive={isActive} />
              },
              {
                path: "fieldreps/:fieldrep_id",
                element: <Surveys isActive={isActive} />
              },
              {
                path: "deadlineSurveys",
                element: <Surveys isActive={isActive} />
              },
              {
                path: "preview/:id/:status",
                element: <PreviewSurvey isActive={isActive} />
                // element: <Surveys isActive={isActive} />,
              },
              {
                path: "preview/edit/:id/:status",
                element: <PreviewSurveyAddEdit isActive={isActive} />
                // element: <Surveys isActive={isActive} />,
              }
              // {
              //   path: "add",
              //   element: <SurveysAddEdit isActive={isActive} />,
              // },
              // {
              //   path: "edit/:id",
              //   element: <SurveysAddEdit isActive={isActive} />,
              // },
            ]
          },
          {
            path: "drafts",
            children: [
              {
                index: true,
                element: <SurveyTemplates isActive={isActive} />
              },
              {
                exact: true,
                path: "add",
                element: <SurveyAddEdit isActive={isActive} />
              },
              {
                exact: true,
                path: "edit/:id",
                element: <SurveyAddEdit isActive={isActive} />
              }
            ]
          },
          {
            path: "published",
            children: [
              {
                index: true,
                element: <SurveyTemplates isActive={isActive} />
              },
              {
                exact: true,
                path: "add",
                element: <SurveyAddEdit isActive={isActive} />
              },
              {
                exact: true,
                path: "edit/:id",
                element: <SurveyAddEdit isActive={isActive} />
              },
              {
                exact: true,
                path: "tags/:id",
                element: <SurveyTag isActive={isActive} />
              }
            ]
          },
          {
            path: "users",
            children: [
              {
                index: true,
                element: <Users isActive={isActive} />
              },
              {
                exact: true,
                path: "add",
                element: <UsersAddEdit isActive={isActive} />
              },
              {
                exact: true,
                path: "edit/:id",
                element: <UsersAddEdit isActive={isActive} />
              }
            ]
          },
          {
            path: "roles",
            children: [
              {
                index: true,
                element: <RolesPermission isActive={isActive} />
              },
              {
                exact: true,
                path: "add",
                element: <RolesPermissionAddEdit isActive={isActive} />
              },
              {
                exact: true,
                path: "edit/:id",
                element: <RolesPermissionAddEdit isActive={isActive} />
              }
            ]
          },
          {
            path: "reportIssue",
            children: [
              {
                index: true,
                element: <ReportAnIssue isActive={isActive} />
              },
              {
                path: "issuereceived",
                element: <IssueReceived isActive={isActive} />
              }
            ]
          },
          {
            path: "fieldreps",
            children: [
              {
                index: true,
                element: <Fieldreps isActive={isActive} />
              },
              {
                path: "add",
                element: <FieldrepsAddEdit isActive={isActive} />
              },
              {
                path: "edit/:id",
                element: <FieldrepsAddEdit isActive={isActive} />
              }
            ]
          },
          {
            path: "sites",
            children: [
              {
                index: true,
                element: <Sites isActive={isActive} />
              },
              {
                path: "add",
                element: <SitesAddEdit isActive={isActive} />
              },
              {
                path: "edit/:id",
                element: <SitesAddEdit isActive={isActive} />
              }
            ]
          },
          {
            path: "projects",
            children: [
              {
                index: true,
                element: <Projects isActive={isActive} />
              },
              {
                path: ":id",
                element: <ProjectDashboard isActive={isActive} />
              },
              {
                path: "add",
                element: <ProjectsAddEdit isActive={isActive} />
              },
              {
                path: "edit/:id",
                element: <ProjectsAddEdit isActive={isActive} />
              }
            ]
          },
          {
            path: "rounds",
            children: [
              {
                index: true,
                element: <Rounds isActive={isActive} />
              },
              {
                path: ":project_id",
                element: <Rounds isActive={isActive} />
              },
              {
                path: "add",
                element: <RoundsAddEdit isActive={isActive} />
              },
              {
                path: "edit/:id",
                element: <RoundsAddEdit isActive={isActive} />
              }
            ]
          },
          {
            path: "emailTemplates",
            children: [
              {
                index: true,
                element: <EmailTemplates isActive={isActive} />
              },
              {
                path: "add",
                element: <EmailTemplatesAddEdit isActive={isActive} />
              },
              {
                path: ":id",
                element: <EmailTemplatesAddEdit isActive={isActive} />
              }
            ]
          },
          {
            path: "broadcastMessages",
            children: [
              {
                index: true,
                element: <BroadcastMessages isActive={isActive} />
              },
              {
                path: "recievedMessages",
                element: <BroadcastReceivedMessages isActive={isActive} />
              }
            ]
          },
          {
            path: "exportSurvey",
            element: <ExportSurvey isActive={isActive} />
          },
          {
            path: "settings",
            element: <Settings isActive={isActive} />
          },
          {
            path: "settings/my-account",
            element: <MyAccount />
          },
          {
            path: "profile",
            element: <Profile isActive={isActive} />
          },
          {
            path: "notifications",
            element: <Notification isActive={isActive} />
          },
          {
            path: "recruitment",
            element: <Recruitment isActive={isActive} />
          },
          {
            path: "reports",
            element: <Reports isActive={isActive} />
          },
          {
            path: "help",
            element: <Help isActive={isActive} />
          },
          // {
          //   path: "logout",
          //   element: <Logout />
          // },
 
          //import
          {
            path: "imports",
            children: [
              {
                index: true,
                element: <Navigate to="/imports/projects" replace={true} />
              },
              {
                exact: true,
                path: "projects",
                element: <ProjectsImport />
              },
              {
                path: "chains",
                element: <ChainsImport />
              },
              {
                path: "clients",
                element: <ClientsImport />
              },
              {
                path: "fieldsrep",
                element: <FieldRepsImport />
              },
              {
                path: "rounds",
                element: <RoundsImport />
              },
              {
                path: "sites",
                element: <SitesImport />
              },
              {
                path: "surveys",
                element: <SurveysImport />
              }
            ]
          }
        ]
      },
 
      { path: "*", element: <ErrorPage /> }
    ]
  } else if (authSelector?.user?.user_type == "AA" && (hostname == adminhost || hostname == 'localhost' || hostname == staginghost)) {
    const defaultUsers = [
      {
        path: "/",
        element: <ProtectedRoute redirectPath="/login" auth={authSelector.auth} />,
        children: [
          {
            index: true,
            element: <Dashboard isActive={isActive} />
          },
          {
            path: "/login",
            element: <Navigate to="/" replace={true} />
          },
          {
            exact: true,
            path: "dashboard",
            element: <Dashboard isActive={isActive} />
          },
          {
            path: "users",
            children: [
              {
                index: true,
                element: <Users isActive={isActive} />
              },
              {
                exact: true,
                path: "add",
                element: <UsersAddEdit isActive={isActive} />
              },
              {
                exact: true,
                path: "edit/:id",
                element: <UsersAddEdit isActive={isActive} />
              }
            ]
          },
          {
            path: "notifications",
            element: <Notification isActive={isActive} />
          },
          {
            path: "roles",
            children: [
              {
                index: true,
                element: <RolesPermission isActive={isActive} />
              },
              {
                exact: true,
                path: "add",
                element: <RolesPermissionAddEdit isActive={isActive} />
              },
              {
                exact: true,
                path: "edit/:id",
                element: <RolesPermissionAddEdit isActive={isActive} />
              }
            ]
          },
          {
            path: "reportIssue",
            children: [
              {
                index: true,
                element: <ReportAnIssue isActive={isActive} />
              },
              {
                path: "issuereceived",
                element: <IssueReceived isActive={isActive} />
              }
            ]
          },
          {
            path: "emailTemplates",
            children: [
              {
                index: true,
                element: <EmailTemplates isActive={isActive} />
              },
              {
                path: "add",
                element: <EmailTemplatesAddEdit isActive={isActive} />
              },
              {
                path: ":id",
                element: <EmailTemplatesAddEdit isActive={isActive} />
              }
            ]
          },
          {
            path: "exportSurvey",
            element: <ExportSurvey isActive={isActive} />
          },
          {
            path: "settings",
            element: <Settings isActive={isActive} />
          },
          {
            path: "settings/my-account",
            element: <MyAccount />
          },
          {
            path: "profile",
            element: <Profile isActive={isActive} />
          },
          {
            path: "broadcastMessages",
            element: <BroadcastMessages isActive={isActive} />
          },
          {
            path: "recruitment",
            element: <Recruitment isActive={isActive} />
          },
          {
            path: "reports",
            element: <Reports isActive={isActive} />
          },
          {
            path: "help",
            element: <Help isActive={isActive} />
          }
        ]
      },
      { path: "*", element: <ErrorPage /> }
    ]
    authSelector?.user_permissions?.forEach((permission) => {
      switch (permission?.entity_name) {
        case "Projects":
          let projectChildren = []
          if (permission?.permission?.includes("add_project")) {
            projectChildren.push({
              path: "add",
              element: (
                <ProjectsAddEdit
                  exact={true}
                  isActive={isActive}
                  permission={permission?.permission}
                />
              )
            })
          }
          if (permission?.permission?.includes("edit_project")) {
            projectChildren.push({
              path: "edit/:id",
              exact: true,
              element: <ProjectsAddEdit isActive={isActive} permission={permission?.permission} />
            })
            projectChildren.push({
              path: ":id",
              exact: true,
              element: (
                <ProjectDashboard
                  exact={true}
                  isActive={isActive}
                  permission={permission?.permission}
                />
              )
            })
          }
          if (permission?.permission?.includes("view_project")) {
            projectChildren.push({
              index: true,
              element: <Projects isActive={isActive} permission={permission?.permission} />
            })
          }
          defaultUsers[0].children.push({
            path: "projects",
            children: projectChildren
          })
          break
 
        case "Rounds":
          let roundChildren = []
          if (permission?.permission?.includes("add_round")) {
            roundChildren.push({
              path: "add",
              exact: true,
              element: (
                <RoundsAddEdit exact isActive={isActive} permission={permission?.permission} />
              )
            })
          }
          if (permission?.permission?.includes("edit_round")) {
            roundChildren.push({
              path: "edit/:id",
              exact: true,
              element: (
                <RoundsAddEdit
                  isActive={isActive}
                  exact={true}
                  permission={permission?.permission}
                />
              )
            })
          }
          if (permission?.permission?.includes("view_round")) {
            roundChildren.push({
              index: true,
              exact: true,
              element: (
                <Rounds isActive={isActive} exact={true} permission={permission?.permission} />
              )
            })
 
            roundChildren.push({
              path: ":project_id",
              exact: true,
              element: <Rounds isActive={isActive} permission={permission?.permission} />
            })
          }
          defaultUsers[0].children.push({
            path: "rounds",
            exact: true,
            children: roundChildren
          })
          // defaultUsers[0].children.push({
          //   path: "rounds",
          //   children: [
          // {
          //   index: true,
          //   element: <Rounds isActive={isActive} permission={permission?.permission} />
          // },
          // {
          //   path: ":project_id",
          //   element: <Rounds isActive={isActive} permission={permission?.permission} />
          // },
          // {
          //   path: "add",
          //   element: <RoundsAddEdit isActive={isActive} permission={permission?.permission} />
          // },
          // {
          //   path: "edit/:id",
          //   element: <RoundsAddEdit isActive={isActive} permission={permission?.permission} />
          // }
          //   ]
          // })
          break
        case "Surveys":
          let surveyChildren = []
 
          if (permission?.permission?.includes("edit_survey")) {
            surveyChildren.push({
              path: "preview/edit/:id/:status",
              element: (
                <PreviewSurveyAddEdit isActive={isActive} permission={permission?.permission} />
              )
            })
          }
          if (permission?.permission?.includes("view_survey")) {
            surveyChildren.push({
              index: true,
              element: <Surveys isActive={isActive} permission={permission?.permission} />
            })
 
            surveyChildren.push({
              path: ":round_id",
              element: <Surveys isActive={isActive} permission={permission?.permission} />
            })
            surveyChildren.push({
              path: "projectSurveys/:round_id",
              element: <Surveys isActive={isActive} permission={permission?.permission} />
            })
            surveyChildren.push({
              path: "preview/:id/:status",
              element: <PreviewSurvey isActive={isActive} permission={permission?.permission} />
            })
            surveyChildren.push({
              path: "fieldreps/:fieldrep_id",
              element: <Surveys isActive={isActive} />
            })
          }
          defaultUsers[0].children.push({
            path: "surveys",
            exact: true,
            children: surveyChildren
          })
 
          break
        case "Clients":
          let clientChildren = []
          if (permission?.permission?.includes("add_client")) {
            clientChildren.push({
              exact: true,
              path: "add",
              element: <ClientsAddEdit isActive={isActive} permission={permission?.permission} />
            })
          }
          if (permission?.permission?.includes("edit_client")) {
            clientChildren.push({
              exact: true,
              path: "edit/:id",
              element: <ClientsAddEdit isActive={isActive} permission={permission?.permission} />
            })
          }
          if (permission?.permission?.includes("view_client")) {
            clientChildren.push({
              exact: true,
              index: true,
              element: <Clients isActive={isActive} permission={permission?.permission} />
            })
          }
          defaultUsers[0].children.push({
            path: "clients",
            exact: true,
            children: clientChildren
          })
          // defaultUsers[0].children.push({
          //   path: "clients",
          //   children: [
          // {
          //   exact: true,
          //   index: true,
          //   element: <Clients isActive={isActive} permission={permission?.permission} />
          // },
          // {
          //   exact: true,
          //   path: "add",
          //   element: <ClientsAddEdit isActive={isActive} permission={permission?.permission} />
          // },
          // {
          //   exact: true,
          //   path: "edit/:id",
          //   element: <ClientsAddEdit isActive={isActive} permission={permission?.permission} />
          // }
          //   ]
          // })
          break
        case "Chains":
          let chainChildren = []
          if (permission?.permission?.includes("add_chain")) {
            chainChildren.push({
              path: "add",
              element: <ChainsAddEdit isActive={isActive} permission={permission?.permission} />
            })
          }
          if (permission?.permission?.includes("edit_chain")) {
            chainChildren.push({
              path: "edit/:id",
              element: <ChainsAddEdit isActive={isActive} permission={permission?.permission} />
            })
          }
          if (permission?.permission?.includes("view_chain")) {
            chainChildren.push({
              index: true,
              element: <Chains isActive={isActive} permission={permission?.permission} />
            })
          }
          defaultUsers[0].children.push({
            path: "chains",
            exact: true,
            children: chainChildren
          })
          // defaultUsers[0].children.push({
          //   path: "chains",
          //   children: [
          // {
          //   index: true,
          //   element: <Chains isActive={isActive} permission={permission?.permission} />
          // },
          // {
          //   path: "add",
          //   element: <ChainsAddEdit isActive={isActive} permission={permission?.permission} />
          // },
          // {
          //   path: "edit/:id",
          //   element: <ChainsAddEdit isActive={isActive} permission={permission?.permission} />
          // }
          //   ]
          // })
          break
        case "Sites":
          let siteChildren = []
          if (permission?.permission?.includes("add_site")) {
            siteChildren.push({
              path: "add",
              element: <SitesAddEdit isActive={isActive} permission={permission?.permission} />
            })
          }
          if (permission?.permission?.includes("edit_site")) {
            siteChildren.push({
              path: "edit/:id",
              element: <SitesAddEdit isActive={isActive} permission={permission?.permission} />
            })
          }
          if (permission?.permission?.includes("view_site")) {
            siteChildren.push({
              index: true,
              element: <Sites isActive={isActive} permission={permission?.permission} />
            })
          }
          defaultUsers[0].children.push({
            path: "sites",
            exact: true,
            children: siteChildren
          })
          // defaultUsers[0].children.push({
          //   path: "sites",
          //   children: [
          // {
          //   index: true,
          //   element: <Sites isActive={isActive} permission={permission?.permission} />
          // },
          // {
          //   path: "add",
          //   element: <SitesAddEdit isActive={isActive} permission={permission?.permission} />
          // },
          // {
          //   path: "edit/:id",
          //   element: <SitesAddEdit isActive={isActive} permission={permission?.permission} />
          // }
          //   ]
          // })
          break
        case "Fieldreps":
          let fieldrepChildren = []
          if (permission?.permission?.includes("add_fieldrep")) {
            fieldrepChildren.push({
              path: "add",
              element: <FieldrepsAddEdit isActive={isActive} permission={permission?.permission} />
            })
          }
          if (permission?.permission?.includes("edit_fieldrep")) {
            fieldrepChildren.push({
              path: "edit/:id",
              element: <FieldrepsAddEdit isActive={isActive} permission={permission?.permission} />
            })
          }
          if (permission?.permission?.includes("view_fieldrep")) {
            fieldrepChildren.push({
              index: true,
              element: <Fieldreps isActive={isActive} permission={permission?.permission} />
            })
          }
          defaultUsers[0].children.push({
            path: "fieldreps",
            exact: true,
            children: fieldrepChildren
          })
          // defaultUsers[0].children.push({
          //   path: "fieldreps",
          //   children: [
          // {
          //   index: true,
          //   element: (
          //     <Fieldreps
          //       isActive={isActive}
          //       permission={permission?.permission}
          //     />
          //   ),
          // },
          // {
          //   path: "add",
          //   element: (
          //     <FieldrepsAddEdit
          //       isActive={isActive}
          //       permission={permission?.permission}
          //     />
          //   ),
          // },
          // {
          //   path: "edit/:id",
          //   element: (
          //     <FieldrepsAddEdit
          //       isActive={isActive}
          //       permission={permission?.permission}
          //     />
          //   ),
          // },
          //   ],
          // });
          break
        case "Recruitment":
          defaultUsers[0].children.push({
            path: "recruitment",
            element: <Recruitment isActive={isActive} permission={permission?.permission} />
          })
          break
        case "Import":
          defaultUsers[0].children.push({
            path: "imports",
            children: [
              {
                index: true,
                element: <Navigate to="/imports/projects" replace={true} />
              },
              {
                exact: true,
                path: "projects",
                element: <ProjectsImport permission={permission?.permission} />
              },
              {
                path: "chains",
                element: <ChainsImport permission={permission?.permission} />
              },
              {
                path: "clients",
                element: <ClientsImport permission={permission?.permission} />
              },
              {
                path: "fieldsrep",
                element: <FieldRepsImport permission={permission?.permission} />
              },
              {
                path: "rounds",
                element: <RoundsImport permission={permission?.permission} />
              },
              {
                path: "sites",
                element: <SitesImport permission={permission?.permission} />
              },
              {
                path: "surveys",
                element: <SurveysImport permission={permission?.permission} />
              }
            ]
          })
          break
        case "Survey Template":
          let drafrChildren = []
          let publishedChildren = []
          if (permission?.permission?.includes("view_surveytemplate")) {
            drafrChildren.push({
              index: true,
              element: <SurveyTemplates isActive={isActive} permission={permission?.permission} />
            })
            publishedChildren.push({
              index: true,
              element: <SurveyTemplates isActive={isActive} permission={permission?.permission} />
            })
            publishedChildren.push({
              exact: true,
              path: "tags/:id",
              element: <SurveyTag isActive={isActive} permission={permission?.permission} />
            })
          }
          if (permission?.permission?.includes("add_surveytemplate")) {
            drafrChildren.push({
              exact: true,
              path: "add",
              element: <SurveyAddEdit isActive={isActive} permission={permission?.permission} />
            })
            publishedChildren.push({
              exact: true,
              path: "add",
              element: <SurveyAddEdit isActive={isActive} permission={permission?.permission} />
            })
          }
          if (permission?.permission?.includes("edit_surveytemplate")) {
            drafrChildren.push({
              exact: true,
              path: "edit/:id",
              element: <SurveyAddEdit isActive={isActive} permission={permission?.permission} />
            })
            publishedChildren.push({
              exact: true,
              path: "edit/:id",
              element: <SurveyAddEdit isActive={isActive} permission={permission?.permission} />
            })
          }
          defaultUsers[0].children.push({
            path: "drafts",
            exact: true,
            children: drafrChildren
          })
          defaultUsers[0].children.push({
            path: "published",
            exact: true,
            children: publishedChildren
          })
          break
        default:
          break
      }
    })
    defaultUsers.push({ path: "*", element: <ErrorPage /> })
    return defaultUsers
  } else if (authSelector?.user?.user_type == "S" && (hostname == superadminhost || hostname == 'localhost' || hostname == staginghost)) {
    arr = [
      {
        path: "/sa",
        element: <ProtectedRoute redirectPath="/sa" auth={authSelector.auth} />,
        children: [
          {
            index: true,
            element: <Navigate to="/sa/dashboard" replace={true} />
            // element: <SuperAdminDashboard isActive={isActive} />,
          },
          {
            exact: true,
            path: "/sa/dashboard",
            element: <SuperAdminDashboard isActive={isActive} />
          },
          {
            path: "/sa/profile",
            element: <SuperAdminProfile isActive={isActive} />
          },
          {
            path: "/sa/inquiries",
            children: [
              {
                exact: true,
                index: true,
                element: <SuperAdminInquery isActive={isActive} />
              }
            ]
          },
          {
            path: "/sa/reports",
            children: [
              {
                exact: true,
                index: true,
                element: <SuperAdminReports isActive={isActive} />
              }
            ]
          },
          {
            path: "/sa/billing",
            children: [
              {
                exact: true,
                index: true,
                element: <SuperAdminBilling isActive={isActive} />
              }
            ]
          },
          {
            path: "/sa/settings",
            children: [
              {
                exact: true,
                index: true,
                element: <SuperAdminSettings isActive={isActive} />
              }
            ]
          },
          {
            path: "/sa/help",
            children: [
              {
                exact: true,
                index: true,
                element: <SuperAdminHelp isActive={isActive} />
              }
            ]
          },
          {
            path: "/sa/notifications",
            element: <SuperAdminNotifications isActive={isActive} />
          },
          {
            path: "/sa/customers",
            children: [
              {
                exact: true,
                index: true,
                element: <SuperAdminCutomers isActive={isActive} />
              }
            ]
          },
          {
            path: "/sa/broadcastMessages",
            children: [
              {
                exact: true,
                index: true,
                element: <SuperAdminBroadcastMessages isActive={isActive} />
              },
              {
                path: "recievedMessages",
                element: <SuperAdminReceivedBroadcastMsg isActive={isActive} />
              }
            ]
          },
          {
            path: "/sa/tickets",
            children: [
              {
                exact: true,
                index: true,
                element: <SuperAdminTickets isActive={isActive} />
              }
            ]
          },
          {
            path: "/sa/roles",
            children: [
              {
                exact: true,
                index: true,
                element: <SuperAdminRolesPermission isActive={isActive} />
              },
              {
                exact: true,
                path: "add",
                element: <AddEditSaRolesPermissions isActive={isActive} />
              },
              {
                exact: true,
                path: "edit/:id",
                element: <AddEditSaRolesPermissions isActive={isActive} />
              }
            ]
          },
          {
            path: "/sa/users",
            children: [
              {
                exact: true,
                index: true,
                element: <SuperAdminUsers isActive={isActive} />
              },
              {
                exact: true,
                path: "add",
                element: <SuperAdminUsersAddEdit isActive={isActive} />
              },
              {
                exact: true,
                path: "edit/:id",
                element: <SuperAdminUsersAddEdit isActive={isActive} />
              }
            ]
          }
        ]
      },
      { path: "*", element: <ErrorPage /> }
    ]
  } else if (authSelector?.user?.user_type == "SU" && (hostname == superadminhost || hostname == 'localhost' || hostname == staginghost)) {
    const defaultUsers = [
      {
        path: "/sa",
        element: <ProtectedRoute redirectPath="/sa" auth={authSelector.auth} />,
        children: [
          {
            index: true,
            element: <Navigate to="/sa/dashboard" replace={true} />
            // element: <SuperAdminDashboard isActive={isActive} />,
          },
          {
            exact: true,
            path: "/sa/dashboard",
            element: <SuperAdminDashboard isActive={isActive} />
          },
          {
            path: "/sa/profile",
            element: <SuperAdminProfile isActive={isActive} />
          },
          {
            path: "/sa/broadcastMessages",
            children: [
              {
                exact: true,
                index: true,
                element: <SuperAdminBroadcastMessages isActive={isActive} />
              },
              {
                path: "recievedMessages",
                element: <SuperAdminReceivedBroadcastMsg isActive={isActive} />
              }
            ]
          },
          {
            path: "/sa/help",
            element: <SuperAdminHelp isActive={isActive} />
          }
        ]
      },
      { path: "*", element: <ErrorPage /> }
    ]
    authSelector?.user_permissions?.forEach((permission) => {
      switch (permission?.entity_name) {
        case "Inquiries":
          let inquiriesChildren = []
 
          if (permission?.permission?.includes("view_inquiries")) {
            inquiriesChildren.push({
              exact: true,
              index: true,
              element: <SuperAdminInquery isActive={isActive} />
            })
          }
 
          defaultUsers[0].children.push({
            path: "/sa/inquiries",
            children: inquiriesChildren
          })
          break
 
        case "Customers":
          let customerChildren = []
          if (permission?.permission?.includes("view_customers")) {
            customerChildren.push({
              exact: true,
              index: true,
              element: <SuperAdminCutomers isActive={isActive} />
            })
          }
 
          defaultUsers[0].children.push({
            path: "/sa/customers",
            exact: true,
            children: customerChildren
          })
 
          break
        case "Billing":
          let billingChildren = []
 
          if (permission?.permission?.includes("view_billing")) {
            billingChildren.push({
              exact: true,
              index: true,
              element: <SuperAdminBilling isActive={isActive} />
            })
          }
 
          defaultUsers[0].children.push({
            path: "/sa/billing",
            exact: true,
            children: billingChildren
          })
 
          break
        // case "Notifications":
        //   let notificationsChildren = []
        //   if (permission?.permission?.includes("view_notifications")) {
        //     notificationsChildren.push({
        //       exact: true,
        //       index: true,
        //       element: <SuperAdminBroadcastMessages isActive={isActive} />
        //     })
        //   }
 
        //   defaultUsers[0].children.push({
        //     path: "/sa/broadcastMessages",
        //     exact: true,
        //     children: notificationsChildren
        //   })
 
        //   break
        case "Tickets":
          let ticketsChildren = []
          if (permission?.permission?.includes("view_tickets")) {
            ticketsChildren.push({
              exact: true,
              index: true,
              element: <SuperAdminTickets isActive={isActive} />
            })
          }
 
          defaultUsers[0].children.push({
            path: "/sa/tickets",
            exact: true,
            children: ticketsChildren
          })
 
          break
      }
    })
    defaultUsers.push({ path: "*", element: <ErrorPage /> })
    return defaultUsers
  }
 
  return arr
}
 
export const Routes = (authSelector, isActive) => {
  const isSaValue = useSelector((state) => state?.ars?.checkRoleForPathSlice?.isSa)
  console.log(isSaValue, "isSaValue routes")
  return authSelector?.auth
    ? getRoles(authSelector, isActive)
    : hostname === superadminhost
    ? unauthorizedRoutesSa
    : unauthorizedRoutes
}